<template>
  <div class="app-box">
    <div class="statistics-box">
      <div class="statistics-tab">
        <x-time-select :bindYear="selectObject.year" :bindMonth="selectObject.month" @change="timeChange"></x-time-select>
      </div>
      <ul class="statistics-list">
        <li>
          <span>{{xhrData?xhrData.visitCount:0}}</span>
          <i>平均拜访次数</i>
        </li>
        <li>
          <span>{{xhrData?xhrData.visistDays:0}}</span>
          <i>平均拜访天数</i>
        </li>
        <li>
          <span>{{xhrData?xhrData.visistHours:0}}</span>
          <i>平均拜访时长</i>
        </li>
      </ul>
    </div>
    <x-scroll-view :top="1.78">
      <table v-if="xhrData&&xhrData.manStatisticList.length>0" class="data-table double-table" style="width:5rem;">
        <colgroup>
          <col style="width:1rem;">
          <col style="width:0.8rem;">
          <col style="width:0.8rem;">
          <col style="width:1.4rem;">
          <col style="width:1rem;">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>业务员</span>
            </th>
            <th scope="col">
              <span>拜访天数</span>
            </th>
            <th scope="col">
              <span>拜访次数</span>
            </th>
            <th scope="col">
              <span>累计拜访时长</span>
            </th>
            <th scope="col">
              <span>忘记打卡(天)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData.manStatisticList" :key="index" @click="rowEvent(item.staffId);">
            <th scope="row">{{item.staffName}}</th>
            <td>{{item.visistDays}}</td>
            <td>{{item.visitCount}}</td>
            <td>{{item.visistHours}}</td>
            <td>{{item.forgetEndDays}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.manStatisticList.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import noDataBg from "@/components/noDataBg";
import timeSelect from "@/components/timeSelect";
import { cachePage } from "@/script/mixins/cachePage";
import { statisticsSalesman } from "@/api/reportCenter";

export default {
  name: "visitIndex",
  data () {
    return {
      selectObject: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      xhrData: null,
      ajaxEnd: false
    };
  },
  mixins: [cachePage],
  created () {
    document.title = "拜访情况统计";
    this.getDataList();
  },
  methods: {
    getDataList () {
      statisticsSalesman(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    timeChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    },
    rowEvent (staffId) {
      this.$router.push({
        path: `/reportCenter/visit/twoLevel/${this.selectObject.year}/${this.selectObject.month || 0}/${staffId}`
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "拜访情况统计";
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noDataBg.name]: noDataBg,
    [timeSelect.name]: timeSelect
  }
};
</script>

<style scoped>
@import '../../../assets/style/achievements.css';
</style>
